<template>
  <div>

    <div class="card">
      <div class="card-body">

        <div class="position-relative wd-50">
          <input v-on:keyup="loadClients(1)" type="text" class="form-control ps-5" v-model="filters.search" placeholder="Search Site..."> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
        </div>

        <br/>
        <button type="button" style="float: right"  data-bs-toggle="modal" data-bs-target="#createClientModal" class="btn btn-primary px-5">New Client</button>
        <pagination @search="loadClients" :data="pagination"></pagination>

        <table id="example" class="table table-hover" style="width:100%">
          <thead>
            <tr>
              <th><!-- LOGO --></th>
              <th>Name</th>
              <th>Street</th>
              <th>Town</th>
              <th>County</th>
              <th>Postcode</th>
              <th># Sites</th>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="client in clients" :key="client.id" style="cursor: pointer;">
              <td @click="$router.push({name: 'admin.clients.view', params: {id: client.id}})"><secure-image v-if="client.logo" style="height: 1rem;" :id="client.name + '-logo'" :endpoint="`/v1/client/${client.id}/logo`"></secure-image></td>
              <td @click="$router.push({name: 'admin.clients.view', params: {id: client.id}})">{{client.name}}</td>
              <td @click="$router.push({name: 'admin.clients.view', params: {id: client.id}})">{{client.street}}</td>
              <td @click="$router.push({name: 'admin.clients.view', params: {id: client.id}})">{{client.town}}</td>
              <td @click="$router.push({name: 'admin.clients.view', params: {id: client.id}})">{{client.county}}</td>
              <td @click="$router.push({name: 'admin.clients.view', params: {id: client.id}})">{{client.postcode}}</td>
              <td @click="$router.push({name: 'admin.clients.view', params: {id: client.id}})">#{{client.siteCount}}</td>
              <td>
                <button type="button" style="float: right" @click="downloadOpenJobsReport(client)"  class="btn btn-sm btn-info px-5">Open Jobs Report</button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th><!-- LOGO --></th>
              <th>Name</th>
              <th>Street</th>
              <th>Town</th>
              <th>County</th>
              <th>Postcode</th>
              <th># Sites</th>
              <td></td>
            </tr>
          </tfoot>
        </table>

        <pagination @search="loadClients" :data="pagination"></pagination>
      </div>
    </div>

    <!-- MODALS -->

    <div class="modal fade" id="createClientModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Client</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <p>Fields marked <span class="text-danger">*</span> are required</p>
              </div>
              <div class="col-md-12 mb-2">
                <label for="clientName" class="form-label">Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="clientName" v-model="client.name">
              </div>
              <div class="col-md-12 mb-2">
                <label for="clientName" class="form-label">Logo</label>
                <input type="file" accept="image/*" ref="file" id="clientLogo" @change="selectFile" class="form-control">
              </div>
              <div class="col-md-12 mt-2">
                <p>Please provide the address for the company headquarters below. Do not enter site address details here.</p>
              </div>
              <div class="col-md-12 mb-2">
                <label for="clientStreet" class="form-label">Street</label>
                <input type="text" class="form-control" id="clientStreet" v-model="client.street">
              </div>
              <div class="col-md-12 mb-2">
                <label for="clientTown" class="form-label">Town</label>
                <input type="text" class="form-control" id="clientTown" v-model="client.town">
              </div>
              <div class="col-md-12 mb-2">
                <label for="clientcounty" class="form-label">County</label>
                <input type="text" class="form-control" id="clientcounty" v-model="client.county">
              </div>
              <div class="col-md-12 mb-2">
                <label for="clientPostcode" class="form-label">Postcode</label>
                <input type="text" class="form-control" id="clientPostcode" v-model="client.postcode">
              </div>
              <div class="col-md-12 mb-2">
                <label for="clientTelephone" class="form-label">Telephone</label>
                <input type="tel" class="form-control" id="clientTelephone" v-model="client.telephone">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreateClientModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createClient" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import Pagination from '../../../components/Pagination.vue';
import SubmitButton from '../../../components/SubmitButton.vue';
import SecureImage from '../../../components/SecureImage.vue';
import { saveAs } from 'file-saver';
export default {
  components: { Pagination, SubmitButton, SecureImage },
  name: 'admin.clients',
  data(){
    return {
      clients: [],
      filters: {
        search: ""
      },
      pagination: {
        lastPage: 0,
        page: 0,
        showing: 0,
        total: 0
      },
      client: {
        name: '',
        logo: null,
        street: null,
        town: null,
        county: null,
        postcode: null,
        telephone: null
      },
      creating: false
    }
  },
  mounted(){
    this.loadClients()
  },
  methods: {
    loadClients(page = 1){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/client?page=${page}&search=${this.filters.search}`)
      .then(response => {
        this.clients = response.data.clients;
        this.pagination = response.data.pagination;
      })
      .catch(error => {
        this.$error('Failed to load clients.', error);
      });
    },
    selectFile(){
      this.client.logo = this.$refs.file.files[0];
      console.log(this.client.logo);
    },
    createClient(){
      this.creating = true;
      let data = new FormData();
      data.append('name', this.client.name);
      if(this.client.logo !== null){
        data.append('logo', this.client.logo, this.logo_filename);
      }
      data.append('street', this.client.street || "");
      data.append('town', this.client.town || "");
      data.append('county', this.client.county || "");
      data.append('postcode', this.client.postcode || "");
      data.append('telephone', this.client.telephone || "");
      axios.put(`${process.env.VUE_APP_API_URL}/v1/client`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        $('#closeCreateClientModal').click();
        this.$success("Created new client");
        this.creating = false;
        this.client = {
          name: '',
          logo: null,
          street: null,
          town: null,
          county: null,
          postcode: null,
          telephone: null
        }
        this.loadClients(this.pagination.page);
      })
      .catch(error => {
        this.$error("Failed to create new client.", error);
        this.creating = false;
      })
    },
    downloadOpenJobsReport(client){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/reports/client?id=${client.id}`, {
        responseType: "blob"
      })
          .then(response => {
            saveAs(response.data, 'open-jobs-report.pdf');
          })
          .catch(error => {
            this.$error("Failed to generate report", error);
          })
    }

  },
  computed: {
    logo_filename: function(){
        if(this.client.logo == null){
            return "Select File";
        }
        if(this.client.logo == null){
            return "File Selected";
        }
        return this.client.logo.name;
    },
  }
}
</script>